
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import AdminBillingPaymentMethod from '@/components/pages/admin/billing/AdminBillingPaymentMethod.vue'
import { useModals } from '@/compositions/modals'
import type { PaymentMethodType } from '@/definitions/account/billing/types'

export default defineComponent({
  components: {
    AdminBillingPaymentMethod,
  },
  props: {
    paymentMethods: {
      type: Array as PropType<PaymentMethodType[]>,
    },
  },
  setup() {
    const { openModal } = useModals()

    return {
      openModal,
    }
  },
})
