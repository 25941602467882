
import { defineComponent } from 'vue'
import AdminBillingItem from '@/components/pages/admin/billing/shared/AdminBillingItem.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { PlanType } from '@/definitions/admin/billing/types'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmButton,
    AdminBillingItem,
  },
  props: {
    billingTotal: {
      type: Object as PropType<PlanType>,
    },
  },
})
