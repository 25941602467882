import type { PaymentMethodType } from '@/definitions/account/billing/types'

export const paymentMethods: PaymentMethodType[] = [
  {
    image: require('@/assets/images/payment/visa2.svg'),
    title: 'primary method',
    card: '•••• 5449',
    expires: '02/2026',
    isPrimary: true,
  },
  {
    image: require('@/assets/images/payment/paypal2.svg'),
    title: 'backup method 1',
    email: 'mypaypalwallet@example.com',
    isPrimary: false,
  },
]
