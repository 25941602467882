
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import AdminBillingPlans from '@/components/pages/admin/billing/AdminBillingPlans.vue'
import AdminBillingAddons from '@/components/pages/admin/billing/AdminBillingAddons.vue'
import AdminBillingTotal from '@/components/pages/admin/billing/AdminBillingTotal.vue'
import AdminBillingAutoRecharge from '@/components/pages/admin/billing/AdminBillingAutoRecharge.vue'
import AdminBillingDetails from '@/components/pages/admin/billing/AdminBillingDetails.vue'
import AdminBillingPaymentMethods from '@/components/pages/admin/billing/AdminBillingPaymentMethods.vue'
import GettingStartedFaq from '@/components/pages/gettingStarted/GettingStartedFaq.vue'
import { paymentMethods } from '@/definitions/account/billing/data'
import { useModes } from '@/compositions/modes'
import { useModals } from '@/compositions/modals'
import type { PlanType, AddonType } from '@/definitions/admin/billing/types'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    AdminBillingDetails,
    AdminBillingPaymentMethods,
    AdminBillingAutoRecharge,
    AdminBillingTotal,
    AdminBillingPlans,
    AdminBillingAddons,
    WhiteBlock,
    PageContent,
    TmTooltip,
    TmButton,
    GettingStartedFaq,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode, isErrorMode, getMode } = useModes()
    const { openModal } = useModals()
    const router = useRouter()

    const payment = computed(() => isEmptyMode.value ? [] : paymentMethods)
    const autoRechargeStatus = ref(true)

    const handleRechargeClick = () => {
      autoRechargeStatus.value = !autoRechargeStatus.value
      openModal('autoRecharge')
    }

    const addons = ref<AddonType[]>([
      {
        icon: 'tmi-phone-dialpad',
        title: 'Virtual numbers',
        text: '4 numbers',
        cost: '40.2',
        to: { name: 'base.services.senderSettings.numbers' },
      },
      {
        icon: 'tmi-phone-geo',
        title: '10DLC',
        text: '4 campaigns',
        to: { name: 'base.services.senderSettings.10DLC' },
        cost: '46.8',
      },
    ])

    const cpaasTooltip = computed(() => {
      const currentMode = getMode()
      switch (currentMode.value) {
        case 'emptyHalf': return 'You’ve almost reached the message limit of your BYOC trial. Upgrade to a monthly plan now for uninterrupted messaging via CPaaS.'
        case 'error': return 'You’ve reached the message limit of your BYOC trial. Upgrade to a monthly plan to continue messaging via CPaaS.'
        default: return 'This progress bar shows how many messages you\'ve sent via CPaaS and your limit on the BYOC free trial.'
      }
    })

    const plans = computed<PlanType[]>(() => [
      {
        icon: 'credit_card',
        title: 'Pay-as-you-go',
        tooltip: 'Your current spending includes the metered usage of standard services like texting, MMS, calls, lookups, etc.',
        text: 'Current spending',
        cost: '1,216.43',
        costPrefix: 'this month',
      },
      {
        icon: 'tmi-operation-path',
        title: 'Bring your own CPaaS',
        tooltip: cpaasTooltip.value,
        btnText: 'Upgrade',
        btnColor: 'warning',
        progress: [
          {
            name: 'Free trial',
            textProgress: isErrorMode.value ? '1,000 / 1,000' : (isEmptyHalfMode.value ? '900 / 1,000' : '200 / 1,000'),
            value: isErrorMode.value ? 1 : (isEmptyHalfMode.value ? 0.9 : 0.2),
            progressColor: (isEmptyHalfMode.value || isErrorMode.value) ? 'warning--text' : '',
          },
        ],
        clickEvent: () => {
          router.push({ name: 'base.admin.plans' })
        },
      },
    ])

    const billingTotal = computed<PlanType>(() => ({
      icon: 'account_balance_wallet',
      title: 'Total estimated cost',
      tooltip: 'The total estimated cost includes your current spending on standard services plus subscriptions.',
      cost: '2,115',
      text: 'Period: Feb 1 - Feb 29',
    }))

    return {
      addons,
      plans,
      payment,
      billingTotal,
      handleRechargeClick,
      autoRechargeStatus,
      openModal,
    }
  },
})
