
import { defineComponent, ref } from 'vue'

type ItemType = {
  title: string;
  text: string;
}

export default defineComponent({
  setup() {
    const items = ref<ItemType[]>([
      {
        title: 'Full name',
        text: 'Robert Overit',
      },
      {
        title: 'Company name',
        text: 'Nike Ltd.',
      },
      {
        title: 'Accounting emails',
        text: 'billing@nike.com',
      },
      {
        title: 'Billing address',
        text: '40 Green str., office 288,\nLondon,\n44828\nUnited Kingdom ',
      },
      {
        title: 'EU VAT information',
        text: '8454875822',
      },
    ])

    return {
      items,
    }
  },
})
