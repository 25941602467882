
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmDropdown,
    TmButtonMore,
  },
  props: {
    title: {
      type: String,
    },
    email: {
      type: String,
    },
    expires: {
      type: String,
    },
    card: {
      type: String,
    },
    image: {
      type: String,
    },
    isPrimary: {
      type: Boolean,
    },
  },
})
