import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a8092d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-grow-1" }
const _hoisted_2 = { class: "d-flex flex-grow-1 justify-between" }
const _hoisted_3 = { class: "subhead-semi-bold-16 mb-2px lh-20 emphasize--text d-flex align-center" }
const _hoisted_4 = {
  key: 0,
  class: "d-flex align-center ml-1"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "admin-billing-item__text" }
const _hoisted_7 = {
  key: 0,
  class: "body-text-regular-14 lh-20 error--text"
}
const _hoisted_8 = { class: "text-right" }
const _hoisted_9 = { class: "headline-18 lh-24 emphasize--text" }
const _hoisted_10 = { class: "body-text-regular-14 lh-20 nowrap neutral--text" }
const _hoisted_11 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'div'), {
    to: _ctx.to,
    class: "admin-billing-item"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_icon, {
        name: _ctx.icon,
        size: "xLarge",
        class: "neutral--text gt-sm mr-3"
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
              (_ctx.tooltip)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_tm_icon, {
                      name: "tmi-help-outline",
                      class: "light--text pointer"
                    }),
                    _createVNode(_component_tm_tooltip, {
                      "max-width": "300px",
                      white: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "body-text-regular-14 py-3 px-2 lh-20 text-left",
                          innerHTML: _ctx.tooltip
                        }, null, 8, _hoisted_5)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
              (_ctx.to)
                ? (_openBlock(), _createBlock(_component_tm_icon, {
                    key: 0,
                    name: "chevron_right",
                    size: "small",
                    class: "mt-2px"
                  }))
                : _createCommentVNode("", true)
            ]),
            (_ctx.errorText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.errorText), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.cost)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_9, "$" + _toDisplayString(_ctx.cost), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.costPrefix), 1)
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.$slots.button)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _renderSlot(_ctx.$slots, "button", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["to"]))
}