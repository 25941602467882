
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import AdminBillingItem from '@/components/pages/admin/billing/shared/AdminBillingItem.vue'
import type { PlanType } from '@/definitions/admin/billing/types'
import type { PropType } from 'vue'
import TmProgress from '@/components/shared/TmProgress.vue'

export default defineComponent({
  components: {
    TmProgress,
    AdminBillingItem,
    TmTooltip,
    TmButton,
  },
  props: {
    plans: {
      type: Array as PropType<PlanType[]>,
    },
  },
})
