
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { RouteLocationRaw } from 'vue-router'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmTooltip,
  },
  props: {
    title: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
    cost: {
      type: String,
    },
    costPrefix: {
      type: String,
      default: 'per month',
    },
    errorText: {
      type: String,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
  },
})
