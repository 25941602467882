import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_admin_billing_payment_method = _resolveComponent("admin-billing-payment-method")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentMethods, (item, i) => {
      return (_openBlock(), _createBlock(_component_admin_billing_payment_method, _mergeProps({ key: i }, item), null, 16))
    }), 128))
  ]))
}